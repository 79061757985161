import React, { useEffect, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import { WhiteCard } from "@newageerp/v3.bundles.widgets-bundle";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import classNames from "classnames";
import { PropsId } from "../../_generated/_custom/models-cache-data/types";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../_generated/_custom/config/NaePaths";

interface IItem {
  id: number;
  title: string;
  totalIncome: number;
  totalIncomeEur: number;
  totalOutcome: number;
  totalOutcomeEur: number;
  payment: number;
  paymentEur: number;
  date: string;
  currency: string;
  files: any[];
}

export default function ReferencePeriodTable(props: PropsId) {
  const { t } = useTranslation();


  const [showIncoming, setShowIncoming] = useState(true);
  const toggleShowIncoming = () => setShowIncoming(!showIncoming);

  const [showOutcoming, setShowOutcoming] = useState(true);
  const toggleShowOutcoming = () => setShowOutcoming(!showOutcoming);

  const [showPayment, setShowPayment] = useState(true);
  const toggleShowPayment = () => setShowPayment(!showPayment);

  const [getData, getDataParams] = useURequest<IItem>(
    NaePathsMap.post["customReferencePeriodReport"]
  );
  const loadData = () => {
    getData({
      id: props.id,
    });
  };
  useEffect(() => {
    loadData();
  }, []);

  let totalIncome = 0;
  let totalIncomeEur = 0;
  let totalOutcome = 0;
  let totalOutcomeEur = 0;
  let totalPayment = 0;
  let totalPaymentEur = 0;

  getDataParams.data.data.forEach((el) => {
    totalIncome += el.totalIncome;
    totalIncomeEur += el.totalIncomeEur;

    totalOutcome += el.totalOutcome;
    totalOutcomeEur += el.totalOutcomeEur;

    totalPayment += el.payment;
    totalPaymentEur += el.paymentEur;
  });

  const data = getDataParams.data.data.filter((el) => {
    if (!showIncoming && el.totalIncome !== 0) {
      return false;
    }
    if (!showOutcoming && el.totalOutcome !== 0) {
      return false;
    }
    if (!showPayment && el.payment !== 0) {
      return false;
    }
    return true;
  });

  const openEmail = () => {
    const options = {
      extraData: {
        id: props.id,
        schema: NaeSSchemaMap.ReferencePeriod.schema,
      }
    }
    const event = new CustomEvent(
      'SFSOpenEmailForm',
      {
        detail: options
      }
    );
    window.dispatchEvent(event);
  }

  return (
    <Fragment>
      <WhiteCard title={t("Report")}>

        <div className={"tw3-flex tw3-gap-2"}>
          <MainButton
            onClick={toggleShowIncoming}
            color={showIncoming ? "teal" : "blue"}
          >
            {t("Incomes")}
          </MainButton>

          <MainButton
            onClick={toggleShowOutcoming}
            color={showOutcoming ? "teal" : "blue"}
          >
            {t("Expenses")}
          </MainButton>

          <MainButton
            onClick={toggleShowPayment}
            color={showPayment ? "teal" : "blue"}
          >
            {t("Payments")}
          </MainButton>

          <span className="flex-grow"></span>

          <MainButton
            onClick={openEmail}
            color={"purple"}
          >
            {t("Send mail")}
          </MainButton>
        </div>


        <Table
          thead={
            <thead>
              <tr>
                <Th>{t("Document")}</Th>
                <Th>{t("Date")}</Th>
                <Th>{t("Currency")}</Th>
                <Th textAlignment={"tw3-text-right"}>{t('Incomes')}</Th>
                <Th textAlignment={"tw3-text-right"}>{t("Incomes, EUR")}</Th>
                <Th textAlignment={"tw3-text-right"}>{t('Expenses')}</Th>
                <Th textAlignment={"tw3-text-right"}>{t("Expenses, EUR")}</Th>
                <Th textAlignment={"tw3-text-right"}>{t('Payments')}</Th>
                <Th textAlignment={"tw3-text-right"}>{t("Payments, EUR")}</Th>
                <Th></Th>
              </tr>
            </thead>
          }
          tbody={
            <Fragment>
              <tbody>
                {data.map((item: IItem, index: number) => {
                  const isRed =
                    (item.totalIncome > 0 || item.totalOutcome > 0) &&
                    item.files.length === 0;
                  return (
                    <tr key={`p-${item.id}`}>
                      <Td className={classNames({ 'tw3-text-red-500': isRed })}>{item.title}</Td>
                      <Td>{item.date}</Td>
                      <Td>{item.currency}</Td>
                      <Td textAlignment={"tw3-text-right"}>{item.totalIncome.toFixed(2)}</Td>
                      <Td textAlignment={"tw3-text-right"}>{item.totalIncomeEur.toFixed(2)}</Td>
                      <Td textAlignment={"tw3-text-right"}>{item.totalOutcome.toFixed(2)}</Td>
                      <Td textAlignment={"tw3-text-right"}>{item.totalOutcomeEur.toFixed(2)}</Td>
                      <Td textAlignment={"tw3-text-right"}>{item.payment.toFixed(2)}</Td>
                      <Td textAlignment={"tw3-text-right"}>{item.paymentEur.toFixed(2)}</Td>
                      <Td>
                        <Fragment>
                          {item.files.map((f) => {
                            return <p>{f.filename}</p>;
                          })}
                        </Fragment>
                      </Td>
                    </tr>
                  )
                })}

              </tbody>
              <tr className={"total"}>
                <Td colSpan={3}></Td>
                <Td textAlignment={"tw3-text-right"}>
                  {totalIncome.toFixed(2)}
                </Td>
                <Td textAlignment={"tw3-text-right"}>
                  {totalIncomeEur.toFixed(2)}
                </Td>
                <Td textAlignment={"tw3-text-right"}>
                  {totalOutcome.toFixed(2)}
                </Td>
                <Td textAlignment={"tw3-text-right"}>
                  {totalOutcomeEur.toFixed(2)}
                </Td>
                <Td textAlignment={"tw3-text-right"}>
                  {totalPayment.toFixed(2)}
                </Td>
                <Td textAlignment={"tw3-text-right"}>
                  {totalPaymentEur.toFixed(2)}
                </Td>
                <Td></Td>
              </tr>
            </Fragment>
          }
        />


      </WhiteCard>

    </Fragment>
  );
}
