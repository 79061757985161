import React, { Fragment } from 'react'
import { Route } from '@newageerp/v3.templates.templates-core'

export default function CustomUserWrapperRoutes() {
  return (
    <Fragment>
      
    </Fragment>
  )
}
