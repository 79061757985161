import React from 'react'
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { MultipleFilesWidget } from '@newageerp/v3.bundles.widgets-bundle';
import { NaeSSchemaMap } from '../../../config/NaeSSchema';

interface Props {
    fieldKey: string,
}

export default function Files(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    return (
        <MultipleFilesWidget
            items={[
                {
                    folder: `${NaeSSchemaMap.InvoiceIncoming.schema}/${element.id}/10`,
                    title: "Invoice"
                }
            ]}
            isCompact={true}
        />
    )
}
