import { IBankFieldsNae } from './useBankHookNae';
import { IClientFieldsNae } from './useClientHookNae';
import { ICompanyFieldsNae } from './useCompanyHookNae';
import { ICurrencyFieldsNae } from './useCurrencyHookNae';
import { IInvoiceIncomingFieldsNae } from './useInvoiceIncomingHookNae';
import { IInvoiceOutgoingFieldsNae } from './useInvoiceOutgoingHookNae';
import { INoteFieldsNae } from './useNoteHookNae';
import { IPaymentFieldsNae } from './usePaymentHookNae';
import { IPaymentImportFieldsNae } from './usePaymentImportHookNae';
import { IProjectFieldsNae } from './useProjectHookNae';
import { IReferencePeriodFieldsNae } from './useReferencePeriodHookNae';
import { ISalesOfferFieldsNae } from './useSalesOfferHookNae';
import { ISalesOrderFieldsNae } from './useSalesOrderHookNae';
import { IServiceFieldsNae } from './useServiceHookNae';
import { ITaskCategoryFieldsNae } from './useTaskCategoryHookNae';
import { IUserFieldsNae } from './useUserHookNae';
import { SelectorBankNae,SelectorClientNae,SelectorCompanyNae,SelectorCurrencyNae,SelectorInvoiceIncomingNae,SelectorInvoiceOutgoingNae,SelectorNoteNae,SelectorPaymentNae,SelectorPaymentImportNae,SelectorProjectNae,SelectorReferencePeriodNae,SelectorSalesOfferNae,SelectorSalesOrderNae,SelectorServiceNae,SelectorTaskCategoryNae,SelectorUserNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.Bank.className) {
            return {
                dataToCheck: SelectorBankNae(store.getState()),
                fields: IBankFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Client.className) {
            return {
                dataToCheck: SelectorClientNae(store.getState()),
                fields: IClientFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Company.className) {
            return {
                dataToCheck: SelectorCompanyNae(store.getState()),
                fields: ICompanyFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Currency.className) {
            return {
                dataToCheck: SelectorCurrencyNae(store.getState()),
                fields: ICurrencyFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.InvoiceIncoming.className) {
            return {
                dataToCheck: SelectorInvoiceIncomingNae(store.getState()),
                fields: IInvoiceIncomingFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.InvoiceOutgoing.className) {
            return {
                dataToCheck: SelectorInvoiceOutgoingNae(store.getState()),
                fields: IInvoiceOutgoingFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Note.className) {
            return {
                dataToCheck: SelectorNoteNae(store.getState()),
                fields: INoteFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Payment.className) {
            return {
                dataToCheck: SelectorPaymentNae(store.getState()),
                fields: IPaymentFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.PaymentImport.className) {
            return {
                dataToCheck: SelectorPaymentImportNae(store.getState()),
                fields: IPaymentImportFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Project.className) {
            return {
                dataToCheck: SelectorProjectNae(store.getState()),
                fields: IProjectFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.ReferencePeriod.className) {
            return {
                dataToCheck: SelectorReferencePeriodNae(store.getState()),
                fields: IReferencePeriodFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SalesOffer.className) {
            return {
                dataToCheck: SelectorSalesOfferNae(store.getState()),
                fields: ISalesOfferFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SalesOrder.className) {
            return {
                dataToCheck: SelectorSalesOrderNae(store.getState()),
                fields: ISalesOrderFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Service.className) {
            return {
                dataToCheck: SelectorServiceNae(store.getState()),
                fields: IServiceFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.TaskCategory.className) {
            return {
                dataToCheck: SelectorTaskCategoryNae(store.getState()),
                fields: ITaskCategoryFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.User.className) {
            return {
                dataToCheck: SelectorUserNae(store.getState()),
                fields: IUserFieldsNae,    
            }
        }
        return undefined;
}