import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';

import { FieldLabel } from "@newageerp/v3.bundles.form-bundle"
import { MainButton } from "@newageerp/v3.bundles.buttons-bundle";
import { Table, Th, Td } from "@newageerp/v3.bundles.layout-bundle";
import { FileUploadWidget } from "@newageerp/v3.bundles.app-bundle";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../_generated/_custom/config/NaePaths";

interface IImportOutputBalance {
  amount: number;
  currency: string;
  date: string;
}

interface IImportOutputEntry {
  amount: number;
  currency: string;
  reference: string;
  accReference: string;
  status: string;
  bankTransactionCode: {
    domainFamilyCode: string;
    domainFamilySubCode: string;
    domainCode: string;
  };
  bookingDate: string;
  transactionDetails: {
    reference: {
      messageId: string;
      accountServicerReference: string;
      paymentInformationId: string;
      instructionId: string;
      transactionId: string;
      mandateId: string;
      processingId: string;
      accountServicerTransactionId: string;
      endToEndId: string;
    };
    accountId: string;
    accountName: string;
    notes: string;
  };
}

interface IImportOutput {
  accountId: string;
  fromDate: string;
  toDate: string;
  notes: string;
  balances: {
    opening: IImportOutputBalance;
    closing: IImportOutputBalance;
  };
  entries: IImportOutputEntry[];
}

interface Props {
  loadData: () => void,
}

export default function PaymentsImport(props: Props) {
  const history = useHistory();

  const [outputDataAr, setOutputDataAr] = useState<IImportOutput[] | null>(
    null
  );
  const [uploadedFile, setUploadedFile] = useState<any>();
  const { t } = useTranslation();

  const [doUpload, doUploadParams] = useURequest(NaePathsMap.post["paymentsXmlImport"]);

  const doUploadToDb = () => {
    doUpload({ ...uploadedFile, toDb: true }).then((res: any) => props.loadData());
  }

  return (
    <div className="pb-4">
      {/* <UI.Toolbar.Toolbar>
        <UI.Typography.H3 className="text-nsecondary-200">
          {t("Mokėjimų importavimas")}
        </UI.Typography.H3>
      </UI.Toolbar.Toolbar> */}

      <div className="space-y-2">
        <FileUploadWidget
          type={"payments-import"}
          onUpload={(nfiles: any) => {
            Object.keys(nfiles).map((k: string) => {
              const f = nfiles[k];
              setUploadedFile(f);
              doUpload(f).then((res: any) => setOutputDataAr(res.data.data));
            });
          }}
        />

        {!!outputDataAr && (
          <Fragment>

            {outputDataAr.map((outputData) => {
              return (
                <Fragment>
                  <div className="space-y-1">
                    <div className="flex items-center">
                      <FieldLabel className="w-40">{t("Sąskaita")}</FieldLabel>
                      <span>{outputData.accountId}</span>
                    </div>
                    <div className="flex items-center">
                      <FieldLabel className="w-40">{t("Laikotarpis")}</FieldLabel>
                      <span>
                        {outputData.fromDate} - {outputData.toDate}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <FieldLabel className="w-40">{t("Balansas")}</FieldLabel>
                      <span>
                        {outputData.balances.opening.amount}{" "}
                        {outputData.balances.opening.currency} -{" "}
                        {outputData.balances.closing.amount}{" "}
                        {outputData.balances.closing.currency}
                      </span>
                    </div>
                  </div>

                  <Table
                    thead={
                      <thead>
                        <tr>
                          <Th>Date</Th>
                          <Th>Client</Th>
                          <Th>Purpose</Th>
                          <Th>Total</Th>
                        </tr>
                      </thead>
                    }
                    tbody={
                      <tbody>
                        {outputData.entries.map((el: IImportOutputEntry, index: number) => {
                          return <tr id={`l-${index}`}>
                            <Td className="tw3-whitespace-nowrap">{el.bookingDate}</Td>
                            <Td>
                              <Fragment>
                                <p>{el.transactionDetails.accountName}</p>
                                <p>{el.transactionDetails.accountId}</p>
                              </Fragment>
                            </Td>
                            <Td>{el.transactionDetails.notes}</Td>
                            <Td className="tw3-whitespace-nowrap">{`${el.amount} ${el.currency}`}</Td>
                          </tr>
                        })}
                      </tbody>
                    }
                  />
                </Fragment>
              );
            })}

            <MainButton color={"teal"} confirmation={true} onClick={doUploadToDb}>
              {t('Upload')}
            </MainButton>

          </Fragment>
        )}
      </div>
    </div>
  );
}
