import CustomMoreInvoiceIncoming from "./InvoiceIncoming/CustomMoreInvoiceIncoming";
import InvoiceIncomingArray from "./InvoiceIncoming/InvoiceIncomingArray";
import ListSepaExport from "./InvoiceIncoming/ListSepaExport";
import CustomMoreInvoiceOutgoing from "./InvoiceOutgoing/CustomMoreInvoiceOutgoing";
import InvoiceOutgoingArray from "./InvoiceOutgoing/InvoiceOutgoingArray";
import Upload from "./PaymentsImport/Upload";
import ReferencePeriodTable from "./ReferencePeriod/ReferencePeriodTable";
import * as BookmarksBundle from '@newageerp/v3.bundles.bookmarks-bundle'

export const PluginsMap: any = {
    'custom.invoice-incoming.CustomMoreInvoiceIncoming': CustomMoreInvoiceIncoming,
    'custom.invoice-incoming.ListSepaExport': ListSepaExport,
    
    'custom.invoice-outgoing.CustomMoreInvoiceOutgoing': CustomMoreInvoiceOutgoing,

    'custom.payment-import.Upload': Upload,

    'custom.invoice-incoming.array': InvoiceIncomingArray,

    'custom.invoice-outgoing.array': InvoiceOutgoingArray,

    'custom.reference.ReferencePeriodTable': ReferencePeriodTable,

    "CustomBookmarksApp": (comp: string) => {
        // @ts-ignore
        return BookmarksBundle[comp];
    },
}