
import { axiosInstance } from "@newageerp/v3.bundles.utils-bundle";

export const NaePaths = [
    {
        "id": "customReferencePeriodReport",
        "method": "post",
        "path": "/app/custom/reference-period/getReport",
        "parameters": []
    },
    {
        "id": "pingtest",
        "method": "get",
        "path": "/app/tmp/ping",
        "parameters": []
    },
    {
        "id": "paymentsXmlImport",
        "method": "post",
        "path": "/app/payments-import/import",
        "parameters": []
    },
    {
        "id": "pluginsInvoiceIncomingSepaGenerateSepaBatch",
        "method": "post",
        "path": "/app/plugins/InvoiceIncomingSepa/generateSepaBatch",
        "parameters": []
    },
    {
        "id": "pluginsInvoiceIncomingSepaGenerateSepa",
        "method": "post",
        "path": "/app/plugins/InvoiceIncomingSepa/generateSepa",
        "parameters": []
    },
    {
        "id": "pluginsWeekPlanCopyToDay",
        "method": "post",
        "path": "/app/plugins/WeekPlan/copyToDayWeekPlan",
        "parameters": []
    },
    {
        "id": "pluginsWeekPlanUpsert",
        "method": "post",
        "path": "/app/plugins/WeekPlan/Upsert",
        "parameters": []
    },
    {
        "id": "NAEauthGetProfile",
        "method": "post",
        "path": "/app/nae-core/auth/get",
        "parameters": []
    },
    {
        "id": "NaeConfigBuilderList",
        "method": "post",
        "path": "/app/nae-core/config-builder/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigBuilderSave",
        "method": "post",
        "path": "/app/nae-core/config-builder/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigBuilderRemove",
        "method": "post",
        "path": "/app/nae-core/config-builder/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigDefaultsList",
        "method": "post",
        "path": "/app/nae-core/config-defaults/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigDefaultsSave",
        "method": "post",
        "path": "/app/nae-core/config-defaults/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigDefaultsRemove",
        "method": "post",
        "path": "/app/nae-core/config-defaults/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigEditList",
        "method": "post",
        "path": "/app/nae-core/config-edit/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigEditSave",
        "method": "post",
        "path": "/app/nae-core/config-edit/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigEditRemove",
        "method": "post",
        "path": "/app/nae-core/config-edit/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigTabList",
        "method": "post",
        "path": "/app/nae-core/config-tabs/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigTabSave",
        "method": "post",
        "path": "/app/nae-core/config-tabs/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigTabRemove",
        "method": "post",
        "path": "/app/nae-core/config-tabs/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigViewList",
        "method": "post",
        "path": "/app/nae-core/config-view/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigViewSave",
        "method": "post",
        "path": "/app/nae-core/config-view/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigViewRemove",
        "method": "post",
        "path": "/app/nae-core/config-view/removeConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigWidgetList",
        "method": "post",
        "path": "/app/nae-core/config-widgets/listConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigWidgetSave",
        "method": "post",
        "path": "/app/nae-core/config-widgets/saveConfig",
        "parameters": []
    },
    {
        "id": "NaeConfigWidgetRemove",
        "method": "post",
        "path": "/app/nae-core/config-widgets/removeConfig",
        "parameters": []
    },
    {
        "id": "NAEUExport",
        "method": "post",
        "path": "/app/nae-core/export/doExport",
        "parameters": []
    },
    {
        "id": "NAEfileRemove",
        "method": "post",
        "path": "/app/nae-core/files/remove",
        "parameters": []
    },
    {
        "id": "NAEfilesList",
        "method": "post",
        "path": "/app/nae-core/files/list",
        "parameters": []
    },
    {
        "id": "NAEfilesMultipleList",
        "method": "post",
        "path": "/app/nae-core/files/multipleList",
        "parameters": []
    },
    {
        "id": "NAEUImport",
        "method": "post",
        "path": "/app/nae-core/import/mainImport",
        "parameters": []
    },
    {
        "id": "NAEmailsGetData",
        "method": "post",
        "path": "/app/nae-core/mails/getData",
        "parameters": []
    },
    {
        "id": "NAEmailsSend",
        "method": "post",
        "path": "/app/nae-core/mails/send",
        "parameters": []
    },
    {
        "id": "NAENotesReadAll",
        "method": "post",
        "path": "/app/nae-core/plugins/notes/readAllMessages",
        "parameters": []
    },
    {
        "id": "NAEPing",
        "method": "get",
        "path": "/app/nae-core/u/ping",
        "parameters": []
    },
    {
        "id": "NAEUMultipleListForModels",
        "method": "post",
        "path": "/app/nae-core/u/getMultipleForModel",
        "parameters": []
    },
    {
        "id": "NAEUMultipleList",
        "method": "post",
        "path": "/app/nae-core/u/getMultiple",
        "parameters": []
    },
    {
        "id": "NAEUCCaheList",
        "method": "post",
        "path": "/app/nae-core/u/getCache",
        "parameters": []
    },
    {
        "id": "NAEUList",
        "method": "post",
        "path": "/app/nae-core/u/get/{schema}",
        "parameters": [
            {
                "name": "schema",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "string"
                }
            }
        ]
    },
    {
        "id": "NAEUSave",
        "method": "post",
        "path": "/app/nae-core/u/save/{schema}",
        "parameters": [
            {
                "name": "schema",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "string"
                }
            }
        ]
    },
    {
        "id": "NAEUSaveMultiple",
        "method": "post",
        "path": "/app/nae-core/u/saveMultiple",
        "parameters": []
    },
    {
        "id": "NAEURemove",
        "method": "post",
        "path": "/app/nae-core/u/remove/{schema}",
        "parameters": [
            {
                "name": "schema",
                "in": "path",
                "required": true,
                "schema": {
                    "type": "string"
                }
            }
        ]
    },
    {
        "id": "NAEURemoveMultiple",
        "method": "post",
        "path": "/app/nae-core/u/removeMultiple",
        "parameters": []
    },
    {
        "id": "NAEXlsxToArray",
        "method": "post",
        "path": "/app/nae-core/xlsx/toArray",
        "parameters": []
    }
]
export const NaePathsMap = {
    "get": {
        "pingtest": "/app/tmp/ping",
        "NAEPing": "/app/nae-core/u/ping"
    },
    "post": {
        "customReferencePeriodReport": "/app/custom/reference-period/getReport",
        "paymentsXmlImport": "/app/payments-import/import",
        "pluginsInvoiceIncomingSepaGenerateSepaBatch": "/app/plugins/InvoiceIncomingSepa/generateSepaBatch",
        "pluginsInvoiceIncomingSepaGenerateSepa": "/app/plugins/InvoiceIncomingSepa/generateSepa",
        "pluginsWeekPlanCopyToDay": "/app/plugins/WeekPlan/copyToDayWeekPlan",
        "pluginsWeekPlanUpsert": "/app/plugins/WeekPlan/Upsert",
        "NAEauthGetProfile": "/app/nae-core/auth/get",
        "NaeConfigBuilderList": "/app/nae-core/config-builder/listConfig",
        "NaeConfigBuilderSave": "/app/nae-core/config-builder/saveConfig",
        "NaeConfigBuilderRemove": "/app/nae-core/config-builder/removeConfig",
        "NaeConfigDefaultsList": "/app/nae-core/config-defaults/listConfig",
        "NaeConfigDefaultsSave": "/app/nae-core/config-defaults/saveConfig",
        "NaeConfigDefaultsRemove": "/app/nae-core/config-defaults/removeConfig",
        "NaeConfigEditList": "/app/nae-core/config-edit/listConfig",
        "NaeConfigEditSave": "/app/nae-core/config-edit/saveConfig",
        "NaeConfigEditRemove": "/app/nae-core/config-edit/removeConfig",
        "NaeConfigTabList": "/app/nae-core/config-tabs/listConfig",
        "NaeConfigTabSave": "/app/nae-core/config-tabs/saveConfig",
        "NaeConfigTabRemove": "/app/nae-core/config-tabs/removeConfig",
        "NaeConfigViewList": "/app/nae-core/config-view/listConfig",
        "NaeConfigViewSave": "/app/nae-core/config-view/saveConfig",
        "NaeConfigViewRemove": "/app/nae-core/config-view/removeConfig",
        "NaeConfigWidgetList": "/app/nae-core/config-widgets/listConfig",
        "NaeConfigWidgetSave": "/app/nae-core/config-widgets/saveConfig",
        "NaeConfigWidgetRemove": "/app/nae-core/config-widgets/removeConfig",
        "NAEUExport": "/app/nae-core/export/doExport",
        "NAEfileRemove": "/app/nae-core/files/remove",
        "NAEfilesList": "/app/nae-core/files/list",
        "NAEfilesMultipleList": "/app/nae-core/files/multipleList",
        "NAEUImport": "/app/nae-core/import/mainImport",
        "NAEmailsGetData": "/app/nae-core/mails/getData",
        "NAEmailsSend": "/app/nae-core/mails/send",
        "NAENotesReadAll": "/app/nae-core/plugins/notes/readAllMessages",
        "NAEUMultipleListForModels": "/app/nae-core/u/getMultipleForModel",
        "NAEUMultipleList": "/app/nae-core/u/getMultiple",
        "NAEUCCaheList": "/app/nae-core/u/getCache",
        "NAEUList": "/app/nae-core/u/get/{schema}",
        "NAEUSave": "/app/nae-core/u/save/{schema}",
        "NAEUSaveMultiple": "/app/nae-core/u/saveMultiple",
        "NAEURemove": "/app/nae-core/u/remove/{schema}",
        "NAEURemoveMultiple": "/app/nae-core/u/removeMultiple",
        "NAEXlsxToArray": "/app/nae-core/xlsx/toArray"
    }
}

export const NaeApiFunctions = {
    'customReferencePeriodReport': (data: any) => {
        const url = '/app/custom/reference-period/getReport';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pingtest': () => {
        const url = '/app/tmp/ping';
        
        
                    return axiosInstance.get(url);
                    
    },

    'paymentsXmlImport': (data: any) => {
        const url = '/app/payments-import/import';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsInvoiceIncomingSepaGenerateSepaBatch': (data: any) => {
        const url = '/app/plugins/InvoiceIncomingSepa/generateSepaBatch';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsInvoiceIncomingSepaGenerateSepa': (data: any) => {
        const url = '/app/plugins/InvoiceIncomingSepa/generateSepa';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsWeekPlanCopyToDay': (data: any) => {
        const url = '/app/plugins/WeekPlan/copyToDayWeekPlan';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'pluginsWeekPlanUpsert': (data: any) => {
        const url = '/app/plugins/WeekPlan/Upsert';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEauthGetProfile': (data: any) => {
        const url = '/app/nae-core/auth/get';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigBuilderList': (data: any) => {
        const url = '/app/nae-core/config-builder/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigBuilderSave': (data: any) => {
        const url = '/app/nae-core/config-builder/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigBuilderRemove': (data: any) => {
        const url = '/app/nae-core/config-builder/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigDefaultsList': (data: any) => {
        const url = '/app/nae-core/config-defaults/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigDefaultsSave': (data: any) => {
        const url = '/app/nae-core/config-defaults/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigDefaultsRemove': (data: any) => {
        const url = '/app/nae-core/config-defaults/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigEditList': (data: any) => {
        const url = '/app/nae-core/config-edit/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigEditSave': (data: any) => {
        const url = '/app/nae-core/config-edit/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigEditRemove': (data: any) => {
        const url = '/app/nae-core/config-edit/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigTabList': (data: any) => {
        const url = '/app/nae-core/config-tabs/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigTabSave': (data: any) => {
        const url = '/app/nae-core/config-tabs/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigTabRemove': (data: any) => {
        const url = '/app/nae-core/config-tabs/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigViewList': (data: any) => {
        const url = '/app/nae-core/config-view/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigViewSave': (data: any) => {
        const url = '/app/nae-core/config-view/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigViewRemove': (data: any) => {
        const url = '/app/nae-core/config-view/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigWidgetList': (data: any) => {
        const url = '/app/nae-core/config-widgets/listConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigWidgetSave': (data: any) => {
        const url = '/app/nae-core/config-widgets/saveConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NaeConfigWidgetRemove': (data: any) => {
        const url = '/app/nae-core/config-widgets/removeConfig';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUExport': (data: any) => {
        const url = '/app/nae-core/export/doExport';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEfileRemove': (data: any) => {
        const url = '/app/nae-core/files/remove';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEfilesList': (data: any) => {
        const url = '/app/nae-core/files/list';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEfilesMultipleList': (data: any) => {
        const url = '/app/nae-core/files/multipleList';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUImport': (data: any) => {
        const url = '/app/nae-core/import/mainImport';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEmailsGetData': (data: any) => {
        const url = '/app/nae-core/mails/getData';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEmailsSend': (data: any) => {
        const url = '/app/nae-core/mails/send';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAENotesReadAll': (data: any) => {
        const url = '/app/nae-core/plugins/notes/readAllMessages';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEPing': () => {
        const url = '/app/nae-core/u/ping';
        
        
                    return axiosInstance.get(url);
                    
    },

    'NAEUMultipleListForModels': (data: any) => {
        const url = '/app/nae-core/u/getMultipleForModel';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUMultipleList': (data: any) => {
        const url = '/app/nae-core/u/getMultiple';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUCCaheList': (data: any) => {
        const url = '/app/nae-core/u/getCache';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUList': (data: any,schema: string) => {
        const url = '/app/nae-core/u/get/{schema}'.replace('{schema}', schema);
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUSave': (data: any,schema: string) => {
        const url = '/app/nae-core/u/save/{schema}'.replace('{schema}', schema);
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEUSaveMultiple': (data: any) => {
        const url = '/app/nae-core/u/saveMultiple';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEURemove': (data: any,schema: string) => {
        const url = '/app/nae-core/u/remove/{schema}'.replace('{schema}', schema);
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEURemoveMultiple': (data: any) => {
        const url = '/app/nae-core/u/removeMultiple';
        
        
                    return axiosInstance.post(url, data);
                    
    },

    'NAEXlsxToArray': (data: any) => {
        const url = '/app/nae-core/xlsx/toArray';
        
        
                    return axiosInstance.post(url, data);
                    
    },

        }