import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, MenuItemProps, MenuItemWithSave } from '@newageerp/v3.bundles.modal-bundle'
import { useInvoiceIncomingHookNae } from "../../_generated/_custom/hooks/useInvoiceIncomingHookNae";
import { PropsId } from "../../_generated/_custom/models-cache-data/types";
import { NaeApiFunctions } from "../../_generated/_custom/config/NaePaths";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";


export default function CustomMoreInvoiceIncoming(props: PropsId) {
    const element = useInvoiceIncomingHookNae(props.id);


    
    const saveXml = () => {
        NaeApiFunctions.pluginsInvoiceIncomingSepaGenerateSepa({
            id: props.id
        }).then(res => {
            if (res.data.success === 1) {
                window.open(res.data.url, '_blank');
            }
        })
    }

    const { t } = useTranslation();

    return (
        <Fragment>
            <MenuItemWithSave
                //   isDisabled={!element.responseDocsFinish}
                iconName={element.resetDebt ? "pen" : "pen-slash"}
                schema={NaeSSchemaMap.InvoiceIncoming.schema}
                saveData={{ resetDebt: !element.resetDebt }}
                elementId={props.id}
                confirmation={true}
            >
                {t("Write off the debt")}
            </MenuItemWithSave>
            <MenuItem iconName="download" onClick={saveXml}>
                {t('Sepa XML')}
            </MenuItem>
        </Fragment>
    )

}
