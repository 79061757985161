import { useEffect } from "react";
import { NaeSSchemaMap } from '../../_custom/config/NaeSSchema';
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorProjectNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IProjectModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      title: string,
      client: {
              id: number,
            },
      isActivePlanning: boolean,
      isFinished: boolean,
      isOnHold: boolean,
  }

export const IProjectFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "title",
    "client.id",
    "isActivePlanning",
    "isFinished",
    "isOnHold"
];

export function useProjectHookNae(id: number) : IProjectModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorProjectNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.Project.schema,
        fields: IProjectFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
