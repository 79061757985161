import { useEffect } from "react";
import { NaeSSchemaMap } from '../../_custom/config/NaeSSchema';
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorInvoiceIncomingNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IInvoiceIncomingModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      serialNumber: string,
      debt: number,
      totalEur: number,
      totalWithVatEur: number,
      resetDebt: boolean,
  }

export const IInvoiceIncomingFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "serialNumber",
    "debt",
    "totalEur",
    "totalWithVatEur",
    "resetDebt"
];

export function useInvoiceIncomingHookNae(id: number) : IInvoiceIncomingModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorInvoiceIncomingNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.InvoiceIncoming.schema,
        fields: IInvoiceIncomingFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
