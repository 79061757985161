import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import React, { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { TemplatesLoader, useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { useUSave } from '@newageerp/v3.bundles.hooks-bundle';

export default function InvoiceIncomingArray() {
    const { t } = useTranslation();

    const [isPopupShow, setIsPopupShow] = useState(false);
    const togglePopup = () => setIsPopupShow(!isPopupShow);

    const { data: tData } = useTemplatesLoader();

    const [saveData] = useUSave(NaeSSchemaMap.InvoiceService.schema);
    const doSaveProductElement = (data: any, id: number | string): Promise<any> => {
        return saveData(data, id);
    };

    const addElement = (el: any) => {
        const _newItem = { service: el, quantity: 1, price: 0 };

        doSaveProductElement(_newItem, "new").then((res: any) => {
            tData.addElement(res.data.element);
            togglePopup();
        })

    };

    return (
        <Fragment>
            <MainButton iconName='plus' onClick={togglePopup}>
                {t('Add')}
            </MainButton>
            {isPopupShow && (
                <TemplatesLoader
                    templateName="PageInlineList"
                    data={{
                        schema: NaeSSchemaMap.Service.schema,
                        type: "main",
                        isPopup: true,
                        addSelectButton: true,
                        addToolbar: true,

                    }}
                    templateData={{
                        onBack: () => {
                            togglePopup()
                        },
                        onAddSelectButton: (el: any) => {
                            addElement(el);
                        },
                    }}
                />
            )}
        </Fragment>
    )
}
