import { INaeProperty } from "../../v3/utils";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "bank",
        "key": "address",
        "type": "string",
        "format": "text",
        "title": "Adresas",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "bank",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Kodas",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "bank",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "bank",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "bank",
        "key": "swiftCode",
        "type": "string",
        "format": "",
        "title": "SWIFT kodas",
        "additionalProperties": [],
        "description": "",
        "className": "Bank",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "base-entity",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Sukurta",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "datetime"
    },
    {
        "schema": "base-entity",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "base-entity",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Atsakingas asmuo",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "base-entity",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "base-entity",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "datetime"
    },
    {
        "schema": "bookmark",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Sukurta",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "bookmark",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "bookmark",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Atsakingas asmuo",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "bookmark",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "bookmark",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "parentId",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "bookmark",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "parentSchema",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "bookmark",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "bookmark",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "client",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "address",
        "type": "string",
        "format": "text",
        "title": "Adresas",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "client",
        "key": "bank",
        "type": "rel",
        "format": "bank",
        "title": "Bankas",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "bankAccountNumbers",
        "type": "array",
        "format": "string",
        "title": "Sąskaitos numeris",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "client",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Kodas",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "color",
        "type": "string",
        "format": "",
        "title": "Color",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "as": "hex-color",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "defaultCurrency",
        "type": "rel",
        "format": "currency",
        "title": "Valiuta",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "client",
        "key": "defaultLang",
        "type": "string",
        "format": "",
        "title": "Kalba",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "LT",
                "value": "lt",
                "color": "slate"
            },
            {
                "label": "EN",
                "value": "en",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "client",
        "key": "emails",
        "type": "array",
        "format": "string",
        "title": "El. paštas",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "client",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client",
        "key": "invoiceIncomings",
        "type": "array",
        "format": "invoice-incoming",
        "title": "Get the value of invoiceIncomings",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "client",
        "key": "invoiceOutgoings",
        "type": "array",
        "format": "invoice-outgoing",
        "title": "Get the value of invoiceOutgoings",
        "additionalProperties": [
            {
                "mapped": "client"
            }
        ],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "client",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "phones",
        "type": "array",
        "format": "string",
        "title": "Telefonas",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "client",
        "key": "totalPurchasesDebtEurRaw",
        "type": "number",
        "format": "float",
        "title": "Purchases debt, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "client",
        "key": "totalPurchasesEur",
        "type": "number",
        "format": "float",
        "title": "Get the value of totalPurchasesEur",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client",
        "key": "totalPurchasesEurRaw",
        "type": "number",
        "format": "float",
        "title": "Purchases, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "client",
        "key": "totalSalesDebtEurRaw",
        "type": "number",
        "format": "float",
        "title": "Sales debt, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "client",
        "key": "totalSalesEur",
        "type": "number",
        "format": "float",
        "title": "Get the value of totalSalesEur",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "client",
        "key": "totalSalesEurRaw",
        "type": "number",
        "format": "float",
        "title": "Sales, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "client",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "PVM numeris",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "accounterEmail",
        "type": "string",
        "format": "",
        "title": "Buh. el. paštas",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "accounterName",
        "type": "string",
        "format": "",
        "title": "Buh. vardas",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "address",
        "type": "string",
        "format": "",
        "title": "Adresas",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "bank",
        "type": "rel",
        "format": "bank",
        "title": "Bankas",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "company",
        "key": "bankAccountNumber",
        "type": "string",
        "format": "",
        "title": "Sąskaitos numeris",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Kodas",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "El. paštas",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "company",
        "key": "invoiceSerial",
        "type": "string",
        "format": "",
        "title": "Sąskaitų serija",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "Telefonas",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "vatNumber",
        "type": "string",
        "format": "",
        "title": "PVM numeris",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "currency",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "Valiuta",
        "additionalProperties": [],
        "description": "",
        "className": "Currency",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "currency",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Currency",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "day-event",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Get the value of date",
        "additionalProperties": [],
        "description": "",
        "className": "DayEvent",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "day-event",
        "key": "hours",
        "type": "number",
        "format": "float",
        "title": "Get the value of hours",
        "additionalProperties": [],
        "description": "",
        "className": "DayEvent",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "day-event",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "DayEvent",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "day-event",
        "key": "isLocked",
        "type": "boolean",
        "format": "",
        "title": "Get the value of isLocked",
        "additionalProperties": [],
        "description": "",
        "className": "DayEvent",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "day-event",
        "key": "project",
        "type": "rel",
        "format": "project",
        "title": "Get the value of project",
        "additionalProperties": [],
        "description": "",
        "className": "DayEvent",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "day-event",
        "key": "startHour",
        "type": "number",
        "format": "float",
        "title": "Get the value of startHour",
        "additionalProperties": [],
        "description": "",
        "className": "DayEvent",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "day-event",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Get the value of title",
        "additionalProperties": [],
        "description": "",
        "className": "DayEvent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "day-event",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "Get the value of user",
        "additionalProperties": [],
        "description": "",
        "className": "DayEvent",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "day-event",
        "key": "weekPlan",
        "type": "rel",
        "format": "week-plan",
        "title": "Get the value of weekPlan",
        "additionalProperties": [],
        "description": "",
        "className": "DayEvent",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "file",
        "key": "appproved",
        "type": "boolean",
        "format": "",
        "title": "Get the value of appproved",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "file",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Sukurta",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "file",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "file",
        "key": "deleted",
        "type": "boolean",
        "format": "",
        "title": "Get the value of deleted",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "file",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Atsakingas asmuo",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "file",
        "key": "fileName",
        "type": "string",
        "format": "",
        "title": "Get the value of fileName",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "folder",
        "type": "string",
        "format": "",
        "title": "Get the value of folder",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "file",
        "key": "orgFileName",
        "type": "string",
        "format": "",
        "title": "Get the value of orgFileName",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "path",
        "type": "string",
        "format": "",
        "title": "Get the value of path",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "file",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "follow-up",
        "key": "comment",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "follow-up",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "follow-up",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "follow-up",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "follow-up",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "follow-up",
        "key": "onDate",
        "type": "string",
        "format": "date",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "follow-up",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "follow-up",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "follow-up",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "follow-up",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FollowUp",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "invoice-base",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Get the value of client",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceBase",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "invoice-base",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Get the value of company",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceBase",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "invoice-base",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Get the value of currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "InvoiceBase",
        "isDb": false,
        "dbType": "",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "invoice-base",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Valiutos kursas",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceBase",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-base",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Get the value of date",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceBase",
        "isDb": false,
        "dbType": "",
        "naeType": "date"
    },
    {
        "schema": "invoice-base",
        "key": "debt",
        "type": "number",
        "format": "float",
        "title": "Skola",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceBase",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-base",
        "key": "dueDate",
        "type": "string",
        "format": "date",
        "title": "Get the value of dueDate",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceBase",
        "isDb": false,
        "dbType": "",
        "naeType": "date"
    },
    {
        "schema": "invoice-base",
        "key": "resetDebt",
        "type": "boolean",
        "format": "",
        "title": "Anulioti skola",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceBase",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "invoice-base",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Get the value of serialNumber",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceBase",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "invoice-base",
        "key": "totalEur",
        "type": "number",
        "format": "float",
        "title": "Get the value of totalEur",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceBase",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-base",
        "key": "totalWithVatEur",
        "type": "number",
        "format": "float",
        "title": "Get the value of totalWithVatEur",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceBase",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-base",
        "key": "vat",
        "type": "integer",
        "format": "",
        "title": "PVM",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceBase",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "invoice-incoming",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Tiekėjas",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Kompanija",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Valiuta",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "invoice-incoming",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Valiutos kursas",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-incoming",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Data",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "invoice-incoming",
        "key": "debt",
        "type": "number",
        "format": "float",
        "title": "Skola",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-incoming",
        "key": "dueDate",
        "type": "string",
        "format": "date",
        "title": "Apmokėti iki",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "invoice-incoming",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-incoming",
        "key": "payedTotal",
        "type": "number",
        "format": "float",
        "title": "Apmokėta suma, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-incoming",
        "key": "payments",
        "type": "array",
        "format": "payment",
        "title": "Mokėjimai",
        "additionalProperties": [
            {
                "mapped": "invoiceIncoming"
            }
        ],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "invoice-incoming",
        "key": "resetDebt",
        "type": "boolean",
        "format": "",
        "title": "Anulioti skola",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "invoice-incoming",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Numeris",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "invoice-incoming",
        "key": "services",
        "type": "array",
        "format": "invoice-service",
        "title": "Paslaugos",
        "additionalProperties": [
            {
                "mapped": "invoiceIncoming"
            }
        ],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "invoice-incoming",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Suma",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-incoming",
        "key": "totalEur",
        "type": "number",
        "format": "float",
        "title": "Suma, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-incoming",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "Suma su PVM",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-incoming",
        "key": "totalWithVatEur",
        "type": "number",
        "format": "float",
        "title": "Suma su PVM, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-incoming",
        "key": "vat",
        "type": "integer",
        "format": "",
        "title": "PVM",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceIncoming",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-outgoing",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Klientas",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Kompanija",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Valiuta",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "invoice-outgoing",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Valiutos kursas",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Data",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "invoice-outgoing",
        "key": "debt",
        "type": "number",
        "format": "float",
        "title": "Skola",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "dueDate",
        "type": "string",
        "format": "date",
        "title": "Apmokėti iki",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "invoice-outgoing",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-outgoing",
        "key": "payedTotal",
        "type": "number",
        "format": "float",
        "title": "Apmokėta suma, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "payments",
        "type": "array",
        "format": "payment",
        "title": "Mokėjimai",
        "additionalProperties": [
            {
                "mapped": "invoiceOutgoing"
            }
        ],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "invoice-outgoing",
        "key": "resetDebt",
        "type": "boolean",
        "format": "",
        "title": "Anulioti skola",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "invoice-outgoing",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Numeris",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "invoice-outgoing",
        "key": "services",
        "type": "array",
        "format": "invoice-service",
        "title": "Paslaugos",
        "additionalProperties": [
            {
                "mapped": "invoiceOutgoing"
            }
        ],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "invoice-outgoing",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Suma",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "totalEur",
        "type": "number",
        "format": "float",
        "title": "Suma, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "totalVat",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "totalWithVat",
        "type": "number",
        "format": "float",
        "title": "Suma su PVM",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "totalWithVatEur",
        "type": "number",
        "format": "float",
        "title": "Suma su PVM, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-outgoing",
        "key": "vat",
        "type": "integer",
        "format": "",
        "title": "PVM",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceOutgoing",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-service",
        "key": "customTitle",
        "type": "string",
        "format": "",
        "title": "Custom title",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceService",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "invoice-service",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceService",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-service",
        "key": "invoiceIncoming",
        "type": "rel",
        "format": "invoice-incoming",
        "title": "Get the value of invoiceIncoming",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceService",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-service",
        "key": "invoiceOutgoing",
        "type": "rel",
        "format": "invoice-outgoing",
        "title": "Get the value of invoiceOutgoing",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceService",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-service",
        "key": "position",
        "type": "integer",
        "format": "",
        "title": "Pozicija",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceService",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-service",
        "key": "price",
        "type": "number",
        "format": "float",
        "title": "Kaina",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceService",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-service",
        "key": "quantity",
        "type": "number",
        "format": "float",
        "title": "Kiekis",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceService",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-service",
        "key": "service",
        "type": "rel",
        "format": "service",
        "title": "Paslauga",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceService",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-service",
        "key": "serviceName",
        "type": "string",
        "format": "",
        "title": "Paslauga",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceService",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "invoice-service",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Suma",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceService",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "mail",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Sukurta",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "mail",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "mail",
        "key": "customId",
        "type": "string",
        "format": "",
        "title": "Get the value of customId",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Atsakingas asmuo",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "mail",
        "key": "html",
        "type": "string",
        "format": "text",
        "title": "Turinys",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "mail",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "mail",
        "key": "lastStatus",
        "type": "string",
        "format": "",
        "title": "Paskutinis statusas",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "parentId",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "mail",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "parentSchema",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "recipient",
        "type": "string",
        "format": "",
        "title": "Gavėjas",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "mail",
        "key": "subject",
        "type": "string",
        "format": "",
        "title": "Tema",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "type",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "mail",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "Mail",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "note",
        "key": "content",
        "type": "string",
        "format": "text",
        "title": "Komentaras",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "note",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Sukurta",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "note",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "note",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Atsakingas asmuo",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "note",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "note",
        "key": "notify",
        "type": "array",
        "format": "number|string",
        "title": "Informuoti",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "note",
        "key": "notifyAccept",
        "type": "array",
        "format": "number|string",
        "title": "Informuota",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "note",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "parentId",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "note",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "parentSchema",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "note",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "note",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "payment",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Valiuta",
        "additionalProperties": [
            "small",
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Valiutos kursas",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Data",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "payment",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment",
        "key": "invoiceIncoming",
        "type": "rel",
        "format": "invoice-incoming",
        "title": "Gauta sąskaita",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "invoiceOutgoing",
        "type": "rel",
        "format": "invoice-outgoing",
        "title": "Išrašyta sąskaita",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Pastabos",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "payment",
        "key": "paymentId",
        "type": "string",
        "format": "",
        "title": "ID",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "paymentImport",
        "type": "rel",
        "format": "payment-import",
        "title": "Importas",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Numeris",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "Šaltinis",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Suma",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "totalEur",
        "type": "number",
        "format": "float",
        "title": "Suma, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "payment-import",
        "key": "balances",
        "type": "array",
        "format": "string",
        "title": "Balansas",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentImport",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "payment-import",
        "key": "dateFrom",
        "type": "string",
        "format": "date",
        "title": "Data nuo",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentImport",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "payment-import",
        "key": "dateTo",
        "type": "string",
        "format": "date",
        "title": "Data iki",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentImport",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "payment-import",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentImport",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-import",
        "key": "payments",
        "type": "array",
        "format": "payment",
        "title": "Mokėjimai",
        "additionalProperties": [
            {
                "mapped": "paymentImport"
            }
        ],
        "description": "",
        "className": "PaymentImport",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "payment-import",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Numeris",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentImport",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "project",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "Color",
        "additionalProperties": [],
        "description": "",
        "className": "Project",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "project",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "Project",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "project",
        "key": "fee",
        "type": "number",
        "format": "float",
        "title": "Fee",
        "additionalProperties": [],
        "description": "",
        "className": "Project",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "project",
        "key": "finishDate",
        "type": "string",
        "format": "date",
        "title": "Finish date",
        "additionalProperties": [],
        "description": "",
        "className": "Project",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "project",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Project",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "project",
        "key": "isActivePlanning",
        "type": "boolean",
        "format": "",
        "title": "Is active planning",
        "additionalProperties": [],
        "description": "",
        "className": "Project",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "project",
        "key": "isFinished",
        "type": "boolean",
        "format": "",
        "title": "Is finished",
        "additionalProperties": [],
        "description": "",
        "className": "Project",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "project",
        "key": "isOnHold",
        "type": "boolean",
        "format": "",
        "title": "Is on hold",
        "additionalProperties": [],
        "description": "",
        "className": "Project",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "project",
        "key": "parentProject",
        "type": "rel",
        "format": "project",
        "title": "Parent project",
        "additionalProperties": [],
        "description": "",
        "className": "Project",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "project",
        "key": "startDate",
        "type": "string",
        "format": "date",
        "title": "Start date",
        "additionalProperties": [],
        "description": "",
        "className": "Project",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "project",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "Project",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "reference-period",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Kompanija",
        "additionalProperties": [],
        "description": "",
        "className": "ReferencePeriod",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "reference-period",
        "key": "dateFrom",
        "type": "string",
        "format": "date",
        "title": "Data nuo",
        "additionalProperties": [],
        "description": "",
        "className": "ReferencePeriod",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "reference-period",
        "key": "dateTo",
        "type": "string",
        "format": "date",
        "title": "Data iki",
        "additionalProperties": [],
        "description": "",
        "className": "ReferencePeriod",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "reference-period",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReferencePeriod",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "reference-period",
        "key": "totalIncome",
        "type": "number",
        "format": "float",
        "title": "Gauta suma",
        "additionalProperties": [],
        "description": "",
        "className": "ReferencePeriod",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "reference-period",
        "key": "totalOutcome",
        "type": "number",
        "format": "float",
        "title": "Sumokėta suma",
        "additionalProperties": [],
        "description": "",
        "className": "ReferencePeriod",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "reference-period",
        "key": "totalPayment",
        "type": "number",
        "format": "float",
        "title": "Mokėjimai",
        "additionalProperties": [],
        "description": "",
        "className": "ReferencePeriod",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "sales-offer",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Klientas",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOffer",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-offer",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Kompanija",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOffer",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-offer",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Valiuta",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "SalesOffer",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "sales-offer",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Valiutos kursas",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOffer",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "sales-offer",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Data",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOffer",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "sales-offer",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Aprašymas",
        "additionalProperties": [
            {
                "as": "rich_editor"
            }
        ],
        "description": "",
        "className": "SalesOffer",
        "isDb": true,
        "dbType": "longtext",
        "as": "rich_editor",
        "naeType": "text"
    },
    {
        "schema": "sales-offer",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOffer",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sales-offer",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Numeris",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOffer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sales-offer",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Suma",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOffer",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "sales-offer",
        "key": "workTerms",
        "type": "string",
        "format": "",
        "title": "Darbų atlikimo terminai",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOffer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Currency rate",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "sales-order",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "sales-order",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [
            {
                "as": "rich_editor"
            }
        ],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "longtext",
        "as": "rich_editor",
        "naeType": "text"
    },
    {
        "schema": "sales-order",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "sales-order",
        "key": "invoiceOutgoing",
        "type": "rel",
        "format": "invoice-outgoing",
        "title": "Invoice Outgoing",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sales-order",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Serial number",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sales-order",
        "key": "taskSource",
        "type": "string",
        "format": "text",
        "title": "Task source",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "sales-order",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "SalesOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "serial-number",
        "key": "className",
        "type": "string",
        "format": "",
        "title": "Get the value of className",
        "additionalProperties": [],
        "description": "",
        "className": "SerialNumber",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "serial-number",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SerialNumber",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "serial-number",
        "key": "incrementKey",
        "type": "string",
        "format": "",
        "title": "Get the value of incrementKey",
        "additionalProperties": [],
        "description": "",
        "className": "SerialNumber",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "serial-number",
        "key": "incrementValue",
        "type": "integer",
        "format": "",
        "title": "Get the value of incrementValue",
        "additionalProperties": [],
        "description": "",
        "className": "SerialNumber",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "service",
        "key": "code",
        "type": "string",
        "format": "",
        "title": "Kodas",
        "additionalProperties": [],
        "description": "",
        "className": "Service",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "service",
        "key": "defaultPrice",
        "type": "number",
        "format": "float",
        "title": "Kaina",
        "additionalProperties": [],
        "description": "",
        "className": "Service",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "service",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Service",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "service",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "Service",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "task",
        "key": "completed",
        "type": "boolean",
        "format": "",
        "title": "Atliktas",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "task",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "task",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Užduotis",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "task",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Vykdytojas",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "task",
        "key": "dueDate",
        "type": "string",
        "format": "datetime",
        "title": "Atlikti iki",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "task",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "task",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "parentId",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "task",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "parentSchema",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "task",
        "key": "repeatPeriod",
        "type": "integer",
        "format": "",
        "title": "Kartoti",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "enum": [
            {
                "label": "Nekartoti",
                "value": 0,
                "color": "slate"
            },
            {
                "label": "Kiekvieną dieną",
                "value": 1,
                "color": "slate"
            },
            {
                "label": "Kiekvieną darbo dieną",
                "value": 2,
                "color": "slate"
            },
            {
                "label": "Kiekvieną savaitę",
                "value": 3,
                "color": "slate"
            },
            {
                "label": "Kas 2 savaites",
                "value": 5,
                "color": "slate"
            },
            {
                "label": "Kiekvieną mėnesį",
                "value": 4,
                "color": "slate"
            },
            {
                "label": "Kas 2 mėnesius",
                "value": 6,
                "color": "slate"
            },
            {
                "label": "Kas 3 mėnesius",
                "value": 7,
                "color": "slate"
            },
            {
                "label": "Kas 6 mėnesius",
                "value": 8,
                "color": "slate"
            },
            {
                "label": "Kas metus",
                "value": 9,
                "color": "slate"
            }
        ],
        "naeType": "enum_number"
    },
    {
        "schema": "task",
        "key": "taskCategory",
        "type": "rel",
        "format": "task-category",
        "title": "Kategorija",
        "additionalProperties": [],
        "description": "",
        "className": "Task",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "task-category",
        "key": "color",
        "type": "string",
        "format": "",
        "title": "Spalva",
        "additionalProperties": [
            {
                "as": "color"
            }
        ],
        "description": "",
        "className": "TaskCategory",
        "isDb": true,
        "dbType": "varchar",
        "as": "color",
        "naeType": "color"
    },
    {
        "schema": "task-category",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "TaskCategory",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "task-category",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Pavadinimas",
        "additionalProperties": [],
        "description": "",
        "className": "TaskCategory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "template",
        "key": "content",
        "type": "array",
        "format": "string",
        "title": "Content",
        "additionalProperties": [],
        "description": "",
        "className": "Template",
        "isDb": true,
        "dbType": "json",
        "as": "html-editor",
        "naeType": "html-editor"
    },
    {
        "schema": "template",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Template",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "template",
        "key": "slug",
        "type": "string",
        "format": "",
        "title": "Slug",
        "additionalProperties": [],
        "description": "",
        "className": "Template",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "template",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "Template",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "allowedIp",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "El. paštas",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "Vardas",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Vardas, pavardė",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "Pavardė",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "login",
        "type": "string",
        "format": "",
        "title": "Prisijungimo vardas",
        "additionalProperties": [
            {
                "key": "filterSkip"
            }
        ],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "mailSignature",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "Get the value of password",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "permissionGroup",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "plainPassword",
        "type": "string",
        "format": "password",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "user",
        "key": "superUser",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "week-plan",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Get the value of date",
        "additionalProperties": [],
        "description": "",
        "className": "WeekPlan",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "week-plan",
        "key": "hours",
        "type": "number",
        "format": "float",
        "title": "Get the value of hours",
        "additionalProperties": [],
        "description": "",
        "className": "WeekPlan",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "week-plan",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "WeekPlan",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "week-plan",
        "key": "project",
        "type": "rel",
        "format": "project",
        "title": "Get the value of project",
        "additionalProperties": [],
        "description": "",
        "className": "WeekPlan",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "week-plan",
        "key": "user",
        "type": "rel",
        "format": "user",
        "title": "Get the value of user",
        "additionalProperties": [],
        "description": "",
        "className": "WeekPlan",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    }
]