import React, { Fragment } from "react";
import { Route, TemplateParseRoute, TemplateRoute } from "@newageerp/v3.templates.templates-core";

import { MainEdit, MainList, MainViewRoutepoint } from "@newageerp/v3.bundles.app-bundle";


export function AppsRoutes() {
    return (
        <Fragment>
                    </Fragment>
    )
}

export function ListRoutes() {
    return (
        <Fragment>
            <Route path={"/u/:schema/:type/list/"}>
                <MainList />
            </Route>
            <Route path={"/tpl/:templateName"}>
                <TemplateRoute />
            </Route>
            <Route path={"/tplp/:templateName"}>
                <TemplateParseRoute />
            </Route>
        </Fragment>
    )
}

export function EditRoutes() {
    return (
        <Fragment>
            <Route path={"/u/:schema/:type/edit/:id"}>
                <MainEdit />
            </Route>
        </Fragment>
    )
}

export function ViewRoutes() {
    return (
        <Fragment>
            <Route path={"/u/:schema/:type/view/:id"}>
                <MainViewRoutepoint />
            </Route>
        </Fragment>
    )
}

export default function AppRoutes() {
    return (
        <Fragment>
            <ListRoutes/>
            <EditRoutes/>
            <ViewRoutes/>
            <AppsRoutes/>
        </Fragment>
    )
}