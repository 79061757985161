import { ORM } from "redux-orm";
import BankModel from "./BankModel"
import ClientModel from "./ClientModel"
import CompanyModel from "./CompanyModel"
import CurrencyModel from "./CurrencyModel"
import InvoiceIncomingModel from "./InvoiceIncomingModel"
import InvoiceOutgoingModel from "./InvoiceOutgoingModel"
import NoteModel from "./NoteModel"
import PaymentModel from "./PaymentModel"
import PaymentImportModel from "./PaymentImportModel"
import ProjectModel from "./ProjectModel"
import QueueModel from "./QueueModel"
import ReferencePeriodModel from "./ReferencePeriodModel"
import SalesOfferModel from "./SalesOfferModel"
import SalesOrderModel from "./SalesOrderModel"
import ServiceModel from "./ServiceModel"
import TaskCategoryModel from "./TaskCategoryModel"
import UserModel from "./UserModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    BankModel,
    ClientModel,
    CompanyModel,
    CurrencyModel,
    InvoiceIncomingModel,
    InvoiceOutgoingModel,
    NoteModel,
    PaymentModel,
    PaymentImportModel,
    ProjectModel,
    QueueModel,
    ReferencePeriodModel,
    SalesOfferModel,
    SalesOrderModel,
    ServiceModel,
    TaskCategoryModel,
    UserModel,
);

export default orm;