import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PaymentsImport from './PaymentsImport';
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle';
import { Popup } from '@newageerp/v3.bundles.popup-bundle'
interface Props {

}

export default function Upload() {
    const { data: tData } = useTemplatesLoader();

    const { t } = useTranslation();
    const [isComponentVisible, setIsComponentVisible] = useState(false);
    const toggleComponentVisible = () => setIsComponentVisible(!isComponentVisible);

    return (
        <Fragment>
            <ToolbarButton
                iconName="arrow-up-from-bracket"
                onClick={toggleComponentVisible}
            />


            {!!isComponentVisible &&
                <Popup title={"Import"} isPopup={true} onClick={toggleComponentVisible}>
                    <PaymentsImport loadData={() => {
                        window.location.reload();
                    }} />
                </Popup>
            }
        </Fragment>
    )
}
