
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorBankNae, SelectorClientNae, SelectorCompanyNae, SelectorCurrencyNae, SelectorInvoiceIncomingNae, SelectorInvoiceOutgoingNae, SelectorNoteNae, SelectorPaymentNae, SelectorPaymentImportNae, SelectorProjectNae, SelectorReferencePeriodNae, SelectorSalesOfferNae, SelectorSalesOrderNae, SelectorServiceNae, SelectorTaskCategoryNae, SelectorUserNae } from '../models/ormSelectors';
import { useBankHookNae } from '../hooks/useBankHookNae';
import { useClientHookNae } from '../hooks/useClientHookNae';
import { useCompanyHookNae } from '../hooks/useCompanyHookNae';
import { useCurrencyHookNae } from '../hooks/useCurrencyHookNae';
import { useInvoiceIncomingHookNae } from '../hooks/useInvoiceIncomingHookNae';
import { useInvoiceOutgoingHookNae } from '../hooks/useInvoiceOutgoingHookNae';
import { useNoteHookNae } from '../hooks/useNoteHookNae';
import { usePaymentHookNae } from '../hooks/usePaymentHookNae';
import { usePaymentImportHookNae } from '../hooks/usePaymentImportHookNae';
import { useProjectHookNae } from '../hooks/useProjectHookNae';
import { useReferencePeriodHookNae } from '../hooks/useReferencePeriodHookNae';
import { useSalesOfferHookNae } from '../hooks/useSalesOfferHookNae';
import { useSalesOrderHookNae } from '../hooks/useSalesOrderHookNae';
import { useServiceHookNae } from '../hooks/useServiceHookNae';
import { useTaskCategoryHookNae } from '../hooks/useTaskCategoryHookNae';
import { useUserHookNae } from '../hooks/useUserHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'bank') {
    return useBankHookNae;
  }
  if (schema === 'client') {
    return useClientHookNae;
  }
  if (schema === 'company') {
    return useCompanyHookNae;
  }
  if (schema === 'currency') {
    return useCurrencyHookNae;
  }
  if (schema === 'invoice-incoming') {
    return useInvoiceIncomingHookNae;
  }
  if (schema === 'invoice-outgoing') {
    return useInvoiceOutgoingHookNae;
  }
  if (schema === 'note') {
    return useNoteHookNae;
  }
  if (schema === 'payment') {
    return usePaymentHookNae;
  }
  if (schema === 'payment-import') {
    return usePaymentImportHookNae;
  }
  if (schema === 'project') {
    return useProjectHookNae;
  }
  if (schema === 'reference-period') {
    return useReferencePeriodHookNae;
  }
  if (schema === 'sales-offer') {
    return useSalesOfferHookNae;
  }
  if (schema === 'sales-order') {
    return useSalesOrderHookNae;
  }
  if (schema === 'service') {
    return useServiceHookNae;
  }
  if (schema === 'task-category') {
    return useTaskCategoryHookNae;
  }
  if (schema === 'user') {
    return useUserHookNae;
  }
  return selector;
}

export function BankEmptyFieldNae(props: PropsLink) {
  const element = useBankHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ClientEmptyFieldNae(props: PropsLink) {
  const element = useClientHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function CompanyEmptyFieldNae(props: PropsLink) {
  const element = useCompanyHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function CurrencyEmptyFieldNae(props: PropsLink) {
  const element = useCurrencyHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function InvoiceIncomingEmptyFieldNae(props: PropsLink) {
  const element = useInvoiceIncomingHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function InvoiceOutgoingEmptyFieldNae(props: PropsLink) {
  const element = useInvoiceOutgoingHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function NoteEmptyFieldNae(props: PropsLink) {
  const element = useNoteHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PaymentEmptyFieldNae(props: PropsLink) {
  const element = usePaymentHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PaymentImportEmptyFieldNae(props: PropsLink) {
  const element = usePaymentImportHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ProjectEmptyFieldNae(props: PropsLink) {
  const element = useProjectHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ReferencePeriodEmptyFieldNae(props: PropsLink) {
  const element = useReferencePeriodHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SalesOfferEmptyFieldNae(props: PropsLink) {
  const element = useSalesOfferHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SalesOrderEmptyFieldNae(props: PropsLink) {
  const element = useSalesOrderHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ServiceEmptyFieldNae(props: PropsLink) {
  const element = useServiceHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function TaskCategoryEmptyFieldNae(props: PropsLink) {
  const element = useTaskCategoryHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function UserEmptyFieldNae(props: PropsLink) {
  const element = useUserHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

