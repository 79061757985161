import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorBankNae = createSelector(orm.BankModel);
export const SelectorClientNae = createSelector(orm.ClientModel);
export const SelectorCompanyNae = createSelector(orm.CompanyModel);
export const SelectorCurrencyNae = createSelector(orm.CurrencyModel);
export const SelectorInvoiceIncomingNae = createSelector(orm.InvoiceIncomingModel);
export const SelectorInvoiceOutgoingNae = createSelector(orm.InvoiceOutgoingModel);
export const SelectorNoteNae = createSelector(orm.NoteModel);
export const SelectorPaymentNae = createSelector(orm.PaymentModel);
export const SelectorPaymentImportNae = createSelector(orm.PaymentImportModel);
export const SelectorProjectNae = createSelector(orm.ProjectModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorReferencePeriodNae = createSelector(orm.ReferencePeriodModel);
export const SelectorSalesOfferNae = createSelector(orm.SalesOfferModel);
export const SelectorSalesOrderNae = createSelector(orm.SalesOrderModel);
export const SelectorServiceNae = createSelector(orm.ServiceModel);
export const SelectorTaskCategoryNae = createSelector(orm.TaskCategoryModel);
export const SelectorUserNae = createSelector(orm.UserModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'Bank') {
        return SelectorBankNae;
    }
        if (schema === 'Client') {
        return SelectorClientNae;
    }
        if (schema === 'Company') {
        return SelectorCompanyNae;
    }
        if (schema === 'Currency') {
        return SelectorCurrencyNae;
    }
        if (schema === 'InvoiceIncoming') {
        return SelectorInvoiceIncomingNae;
    }
        if (schema === 'InvoiceOutgoing') {
        return SelectorInvoiceOutgoingNae;
    }
        if (schema === 'Note') {
        return SelectorNoteNae;
    }
        if (schema === 'Payment') {
        return SelectorPaymentNae;
    }
        if (schema === 'PaymentImport') {
        return SelectorPaymentImportNae;
    }
        if (schema === 'Project') {
        return SelectorProjectNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
        if (schema === 'ReferencePeriod') {
        return SelectorReferencePeriodNae;
    }
        if (schema === 'SalesOffer') {
        return SelectorSalesOfferNae;
    }
        if (schema === 'SalesOrder') {
        return SelectorSalesOrderNae;
    }
        if (schema === 'Service') {
        return SelectorServiceNae;
    }
        if (schema === 'TaskCategory') {
        return SelectorTaskCategoryNae;
    }
        if (schema === 'User') {
        return SelectorUserNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'bank') {
        return SelectorBankNae;
    }
        if (schema === 'client') {
        return SelectorClientNae;
    }
        if (schema === 'company') {
        return SelectorCompanyNae;
    }
        if (schema === 'currency') {
        return SelectorCurrencyNae;
    }
        if (schema === 'invoice-incoming') {
        return SelectorInvoiceIncomingNae;
    }
        if (schema === 'invoice-outgoing') {
        return SelectorInvoiceOutgoingNae;
    }
        if (schema === 'note') {
        return SelectorNoteNae;
    }
        if (schema === 'payment') {
        return SelectorPaymentNae;
    }
        if (schema === 'payment-import') {
        return SelectorPaymentImportNae;
    }
        if (schema === 'project') {
        return SelectorProjectNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
        if (schema === 'reference-period') {
        return SelectorReferencePeriodNae;
    }
        if (schema === 'sales-offer') {
        return SelectorSalesOfferNae;
    }
        if (schema === 'sales-order') {
        return SelectorSalesOrderNae;
    }
        if (schema === 'service') {
        return SelectorServiceNae;
    }
        if (schema === 'task-category') {
        return SelectorTaskCategoryNae;
    }
        if (schema === 'user') {
        return SelectorUserNae;
    }
    }