import { axiosInstance, publish } from "@newageerp/v3.bundles.utils-bundle";

import moment from 'moment'

export const onEditElementUpdate = (schema: string, key: string, val: any, element: any) => {
    if (schema === 'invoice-incoming' && (key === 'currency' || key === 'date')) {
        try {
            axiosInstance.post('/app/plugins/CustomCurrencyRate/', {
                date: moment(element.date).format('YYYY-MM-DD'),
                currencyId: element.currency.id
            }).then((res) => {
                publish('invoice-incoming-main-Update', { key: 'currencyRate', val: res.data.data })
            })
            
        } catch (e) {

        }
    }
    return element;
    return element;
}