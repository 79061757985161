import React from "react";
import { useTranslation } from "react-i18next";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { useInvoiceOutgoingHookNae } from "../../_generated/_custom/hooks/useInvoiceOutgoingHookNae";
import { PropsId } from "../../_generated/_custom/models-cache-data/types";
import { MenuItemWithSave } from "@newageerp/v3.bundles.modal-bundle";


export default function CustomMoreInvoiceOutgoing(props: PropsId) {
    const element = useInvoiceOutgoingHookNae(props.id);

    const { t } = useTranslation();

    return (
        <MenuItemWithSave
            //   isDisabled={!element.responseDocsFinish}
            iconName={element.resetDebt ? "pen" : "pen-slash"}
            schema={NaeSSchemaMap.InvoiceOutgoing.schema}
            saveData={{ resetDebt: !element.resetDebt }}
            elementId={props.id}
            confirmation={true}
        >
            {t("Write off the debt")}
        </MenuItemWithSave>
    )

}
