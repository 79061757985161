import { useEffect } from "react";
import { NaeSSchemaMap } from '../../_custom/config/NaeSSchema';
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorClientNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IClientModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      name: string,
      color: string,
  }

export const IClientFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "name",
    "color"
];

export function useClientHookNae(id: number) : IClientModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorClientNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.Client.schema,
        fields: IClientFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
