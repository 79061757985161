import React from "react";

import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import { ToolbarButton } from "@newageerp/v3.bundles.buttons-bundle";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { useListDataSource } from "@newageerp/v3.app.list.list-data-source";
import { useURequest } from "@newageerp/v3.bundles.hooks-bundle";
import { NaePathsMap } from "../../_generated/_custom/config/NaePaths";

export default function ListSepaExport() {
    const { data: tData } = useTemplatesLoader();
    const listDataSource = useListDataSource();

    const [doExport] = useURequest(NaePathsMap.post['pluginsInvoiceIncomingSepaGenerateSepaBatch']);

    const onClick = () => {
        const filter = listDataSource.filter.prepare();
        doExport({
            filter,
            schema: NaeSSchemaMap.InvoiceIncoming.schema,
        }).then((res: any) => {
            if (res.data.success === 1) {
                window.open(res.data.url, '_blank');
            }
        })
    };
    return (
        <ToolbarButton
            title={"Sepa eksportas"}
            onClick={onClick}
            iconName={"download"}

        />
    );
}
