import { INaeSchema } from "../../v3/utils"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "Bank",
        "schema": "bank",
        "title": "Bank",
        "titlePlural": "Banks"
    },
    {
        "className": "BaseEntity",
        "schema": "base-entity",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "Bookmark",
        "schema": "bookmark",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "Client",
        "schema": "client",
        "title": "Client",
        "titlePlural": "Clients"
    },
    {
        "className": "Company",
        "schema": "company",
        "title": "Company",
        "titlePlural": "Companies"
    },
    {
        "className": "Currency",
        "schema": "currency",
        "title": "Currency",
        "titlePlural": "Currencies"
    },
    {
        "className": "DayEvent",
        "schema": "day-event",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "File",
        "schema": "file",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "FollowUp",
        "schema": "follow-up",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "InvoiceBase",
        "schema": "invoice-base",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "InvoiceIncoming",
        "schema": "invoice-incoming",
        "title": "Purchase invoice",
        "titlePlural": "Purchase invoices"
    },
    {
        "className": "InvoiceOutgoing",
        "schema": "invoice-outgoing",
        "title": "Sales invoice",
        "titlePlural": "Sales invoices"
    },
    {
        "className": "InvoiceService",
        "schema": "invoice-service",
        "title": "Invoice service",
        "titlePlural": "Invoice services"
    },
    {
        "className": "Mail",
        "schema": "mail",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "Note",
        "schema": "note",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "Payment",
        "schema": "payment",
        "title": "Payment",
        "titlePlural": "Payments"
    },
    {
        "className": "PaymentImport",
        "schema": "payment-import",
        "title": "Payments import",
        "titlePlural": "Payments imports",
        "scopes": [
            "disable-create",
            "disable-edit",
            "disable-remove"
        ]
    },
    {
        "className": "Project",
        "schema": "project",
        "title": "Project",
        "titlePlural": "Projects",
        "required": [
            "title"
        ],
        "scopes": []
    },
    {
        "className": "ReferencePeriod",
        "schema": "reference-period",
        "title": "Reference period",
        "titlePlural": "Reference periods"
    },
    {
        "className": "SalesOffer",
        "schema": "sales-offer",
        "title": "Sales offer",
        "titlePlural": "Sales offers"
    },
    {
        "className": "SalesOrder",
        "schema": "sales-order",
        "title": "Sales order",
        "titlePlural": "Sales orders",
        "required": [],
        "scopes": []
    },
    {
        "className": "SerialNumber",
        "schema": "serial-number",
        "title": "",
        "titlePlural": ""
    },
    {
        "className": "Service",
        "schema": "service",
        "title": "Service",
        "titlePlural": "Services"
    },
    {
        "className": "Task",
        "schema": "task",
        "title": "Užduotis",
        "titlePlural": "Užduotys"
    },
    {
        "className": "TaskCategory",
        "schema": "task-category",
        "title": "Užduoties kategorija",
        "titlePlural": "Užduoties kategorijos"
    },
    {
        "className": "Template",
        "schema": "template",
        "title": "Template",
        "titlePlural": "Templates",
        "required": [],
        "scopes": []
    },
    {
        "className": "User",
        "schema": "user",
        "title": "User",
        "titlePlural": "Users"
    },
    {
        "className": "WeekPlan",
        "schema": "week-plan",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    }
]
        export const NaeSSchemaMap = {
    "Client": {
        "className": "Client",
        "schema": "client"
    },
    "Company": {
        "className": "Company",
        "schema": "company"
    },
    "User": {
        "className": "User",
        "schema": "user"
    },
    "Bank": {
        "className": "Bank",
        "schema": "bank"
    },
    "Currency": {
        "className": "Currency",
        "schema": "currency"
    },
    "InvoiceBase": {
        "className": "InvoiceBase",
        "schema": "invoice-base"
    },
    "InvoiceIncoming": {
        "className": "InvoiceIncoming",
        "schema": "invoice-incoming"
    },
    "InvoiceOutgoing": {
        "className": "InvoiceOutgoing",
        "schema": "invoice-outgoing"
    },
    "InvoiceService": {
        "className": "InvoiceService",
        "schema": "invoice-service"
    },
    "Service": {
        "className": "Service",
        "schema": "service"
    },
    "BaseEntity": {
        "className": "BaseEntity",
        "schema": "base-entity"
    },
    "Bookmark": {
        "className": "Bookmark",
        "schema": "bookmark"
    },
    "File": {
        "className": "File",
        "schema": "file"
    },
    "FollowUp": {
        "className": "FollowUp",
        "schema": "follow-up"
    },
    "Note": {
        "className": "Note",
        "schema": "note"
    },
    "Task": {
        "className": "Task",
        "schema": "task"
    },
    "TaskCategory": {
        "className": "TaskCategory",
        "schema": "task-category"
    },
    "Mail": {
        "className": "Mail",
        "schema": "mail"
    },
    "Payment": {
        "className": "Payment",
        "schema": "payment"
    },
    "PaymentImport": {
        "className": "PaymentImport",
        "schema": "payment-import"
    },
    "SerialNumber": {
        "className": "SerialNumber",
        "schema": "serial-number"
    },
    "ReferencePeriod": {
        "className": "ReferencePeriod",
        "schema": "reference-period"
    },
    "SalesOffer": {
        "className": "SalesOffer",
        "schema": "sales-offer"
    },
    "SalesOrder": {
        "className": "SalesOrder",
        "schema": "sales-order"
    },
    "Template": {
        "className": "Template",
        "schema": "template"
    },
    "WeekPlan": {
        "className": "WeekPlan",
        "schema": "week-plan"
    },
    "DayEvent": {
        "className": "DayEvent",
        "schema": "day-event"
    },
    "Project": {
        "className": "Project",
        "schema": "project"
    }
}