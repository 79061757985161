import React from 'react'
import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';

interface Props {
    fieldKey: string,
}

export default function Totals(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    const payments = element.payments.filter((p: any) => !p.invoiceIncoming && !p.invoiceOutgoing);
    return (<div className={payments.length === 0 ? "text-green-500" : "text-red-500"}>
        {payments.length} / {element.payments.length}
    </div>)
        ;
}
